import { BrowserRouter, Link, Routes, Route, NavLink,useHistory  } from "react-router-dom";
import { Container,Nav,Navbar,NavItem,NavDropdown,NavbarBrand } from "react-bootstrap";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import SoftwareDevelopment from "./pages/SoftwareDevelopment";
import SoftwareTraining from "./pages/SoftwareTraining";
import GraphicDesigning from "./pages/GraphicDesigning";
import Printing from "./pages/Printing";
import Internship from "./pages/Internship";
import HireUs from "./pages/HireUs";
import Gallery from "./pages/Gallery";
import Careers from "./pages/Careers";
import ContactUs from "./pages/ContactUs";
import Logo from "../src/assets/images/AMS Logo.svg";
import SocialActivities from "./pages/SocialActivities";
import ARVR from "./pages/AR&VR";
import { useEffect } from 'react';
import WhyAMS from "./pages/WhyAMS";
import Advertising from "./pages/Advertising";
import StudentProfile from "./components/studentProfile"




function Navigation() {

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
  <div className="container-fluid unique-gutter">
    <div className="row">
      <div className="col-lg-12 col-sm-12 px-md-5 px-sm-2 px-xs-2">
      <Navbar expand="xl" className="bg-body-tertiary">
        <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={Logo} alt="AMS Logo" width='150' onClick={scrollToTop}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink to="/" className="nav-link" onClick={scrollToTop}>Home</NavLink>
            <NavLink to="/about-us" className="nav-link" onClick={scrollToTop}>About Us</NavLink>
            <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/services/software-development" onClick={scrollToTop}>Software Development</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/services/software-training" onClick={scrollToTop}>
              Software Training
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/services/graphic-designing" onClick={scrollToTop}>
              Graphic Designing
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/services/printing" onClick={scrollToTop}> 
              Printing Services
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/services/advertising" onClick={scrollToTop}> 
              Advertising
              </NavDropdown.Item>
            </NavDropdown>
            <Link to="/internship" className="nav-link" onClick={scrollToTop}>Internship</Link>
            <Link to="/augmented-virtual-reality" className="nav-link" onClick={scrollToTop}>AR & VR</Link>
            <NavLink to="/why-ams" className="nav-link" onClick={scrollToTop}>Why AMS</NavLink>
            <NavLink to="/hire-us" className="nav-link" onClick={scrollToTop}>Hire Us</NavLink>
            <NavLink to="/gallery" className="nav-link" onClick={scrollToTop}>Gallery</NavLink>
            {/* <NavLink to="/careers" className="nav-link" onClick={scrollToTop}>Careers</NavLink> */}
            <NavLink to="/social-activities" className="nav-link" onClick={scrollToTop}>Social Activities</NavLink>
            <NavLink to="/contact-us" className="nav-link" onClick={scrollToTop}>Contact Us</NavLink>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
      </div>
      </div>
      </div>
      <Routes>
      <Route path="/" exact element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services/software-development" element={<SoftwareDevelopment />} />
        <Route path="/services/software-training" element={<SoftwareTraining />} />
        <Route path="/services/graphic-designing" element={<GraphicDesigning />} />
        <Route path="/services/printing" element={<Printing />} />
        <Route path="/services/advertising" element={<Advertising />} />
        <Route path="/internship" element={<Internship />} />
        <Route path="/augmented-virtual-reality" element={<ARVR />} />
        <Route path="/why-ams" element={<WhyAMS />} />
        <Route path="/hire-us/*" element={<HireUs />} />
        <Route path="/gallery" element={<Gallery />} />
        {/* <Route path="/careers" element={<Careers />} /> */}
        <Route path="/social-activities" element={<SocialActivities />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/profile/:title" element={<StudentProfile />} />
      </Routes>

   </>
  );
}

export default Navigation;
