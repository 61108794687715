import React from "react";
import { Helmet } from 'react-helmet';
import Banner from "../components/Banner";
import About from "../components/About";
import Services from "../components/services";
import SliderTestimonials from "../components/SliderTestimonials";
import Location from "../components/Location";
import WhyAMS from "../components/WhyAMS";
import LogoSlider from "../components/LogoSlider";

const Home = () => {
  return (
    <>
    <Helmet>
        <title>AMS - Innovating Your Tomorrow</title>
        <meta name="description" content="Explore innovation and technology with AMS. We provide cutting-edge software solutions, comprehensive training programs, creative graphic design, top-notch printing services, impactful advertising, immersive AR & VR experiences, and more." />
        <meta name="keywords" content="AMS, innovation, technology, software development, training, graphic design, printing, advertising, AR & VR" />
        <meta name="robots" content="index, follow" />
      </Helmet>

    <div className="container-fluid unique-gutter">
      <Banner/>
      <About/>
      <WhyAMS/>
      <Services/>
      <SliderTestimonials/>
      <LogoSlider/>
      <Location/>
    </div>
    </>
  );
};

export default Home;
