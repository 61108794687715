import React from "react";
import { Helmet } from 'react-helmet';
import printingServicesData from "../api/printingServicesData.json"

const Printing = () => {
  return (
    <>
    <Helmet>
        <title>Printing Services - AMS</title>
        <meta name="description" content="Turn your ideas into vibrant reality with AMS's printing services. From business cards and brochures to banners and promotional materials, we deliver high-quality prints that make a lasting impression." />
        <meta name="keywords" content="AMS, printing services, business cards, brochures, banners, promotional materials, custom printing" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    <div className="container">
    <div className="row">
      <div className="col-lg-12 col-sm-12 about p-md-5 p-sm-5 p-xs-3">
      <h1 className="text-center">{printingServicesData.title}</h1>
              <h5 className="text-center">{printingServicesData.subtitle}</h5>
              <p>{printingServicesData.description}</p>
              <h5>{printingServicesData.comprehensivePrintingServices.heading}</h5>
              <p>{printingServicesData.comprehensivePrintingServices.description}</p>
              <h5>{printingServicesData.whyChooseAMS.heading}</h5>
              <ul>
                {printingServicesData.whyChooseAMS.details.map((detail, index) => (
                  <li key={index}>{detail}</li>
                ))}
              </ul>
              <h5>{printingServicesData.elevatePrintProjects.heading}</h5>
              <p>{printingServicesData.closingStatement}</p>
    </div>
    </div>
    </div>
    </>
  );
};

export default Printing;
