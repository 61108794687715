const CourseCard = (props)=>{
    return(
        <div col-lg-4 col-md-6 col-sm-12>
        <div className="card my-5 x-margin">
                <div className="card-body">
                    <h2 className="text-center course-title">{props.courseTitle}</h2>
                    <div className="qualification-duration d-flex justify-content-between my-3">
                        <div className="qualification">
                            <h3 className="m-0">{props.qualificationTitle}</h3>
                            <span className="d-block text-center">{props.qualification}</span>
                        </div>
                        <div className="duration">
                            <h3 className="m-0">{props.durationTitle}</h3>
                            <span className="d-block text-center">{props.courseDuration}</span>
                        </div>
                    </div>
                    <div className="technologies text-center mt-3">
                        <h4 className="m-2">Syllabus We Cover</h4>
                        <table className="table table-bordered table-custom-bg">
                            <thead>
                                <tr>
                                    <th className="sub-title">S.No.</th>
                                    <th className="sub-title">Technology Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {props.technologies}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="text-center">
                <a href="#"><button type="button" class="mb-3 mx-auto btn btn-primary">Know More</button></a>
                </div>
            </div>
            </div>
    )
}
export default CourseCard;