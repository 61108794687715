import React from "react";
import { Helmet } from 'react-helmet';
import softwareDevelopmentData from "../api/softwareDevelopmentData.json"
import ServicesCard from "../components/servicesCard";

const SoftwareDevelopment = () => {
  const sections = [
    softwareDevelopmentData.webDevelopment,
    softwareDevelopmentData.guiApplications,
    softwareDevelopmentData.hostingServices,
    softwareDevelopmentData.technologies,
    softwareDevelopmentData.innovation,
  ];
  return (
    <>
     <Helmet>
        <title>Software Development Services - AMS</title>
        <meta name="description" content="AMS specializes in custom software development services. From conceptualization to deployment, our experienced team crafts innovative and scalable solutions tailored to meet your business needs." />
        <meta name="keywords" content="AMS, software development, custom software, technology solutions, software engineering, innovation" />
        <meta name="robots" content="index, follow" />
      </Helmet>

    <div className="container">
    <div className="row">
      <div className="col-lg-12 col-sm-12 about p-md-5 p-sm-5 p-xs-3">
      <h1 className="text-center">{softwareDevelopmentData.title}</h1>
      <h5 className="text-center">{softwareDevelopmentData.subtitle}</h5>
      <p>{softwareDevelopmentData.description}</p>
      {sections.map((section, index) => (
        <div key={index}>
          {/* <h3>{section.heading}</h3>
          <p>{section.details}</p> */}
          
          <ServicesCard
          mainTitle={section.heading.split(":")[0].trim()}
          cardTitle={section.heading.split(":")[1].trim()}
          cardText={section.details}
          buttonLabel="View More"         
          />
        </div>
      ))}


            <p>{softwareDevelopmentData.closingRemarks}</p>
    </div>
    </div>
    </div>
    </>
      );
};

export default SoftwareDevelopment;
