import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const About = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-sm-12 about p-md-5 p-sm-5 p-xs-3">

              <h2 className='text-center'>Welcome to AMS</h2>
              <h6 className='text-center pb-2'>Your Gateway to Innovation</h6>
              <p className='text-justify'>Since our inception in 2019, AMS has been on a remarkable journey, weaving together technology, innovation, and the spirit of empowerment. We're not just a software company; we are a catalyst for change, a beacon of progress, and a bridge between rural communities and the digital world. With a relentless commitment to redefining the boundaries of what's possible, we've made it our mission to lead the charge in bringing technology and innovation to the far reaches of the world. Our story is one of passion, perseverance, and the unwavering belief that every individual, regardless of their location, deserves the opportunity to thrive in the digital age.</p>  
              
          <div className='text-center'>
          <Link to='/about-us' onClick={scrollToTop}>
            <Button variant="primary" className="my-4 mx-auto">
              Read More
            </Button>
          </Link>
          </div>
          </div>
      </div>
    </div>
  );
};

export default About;
