import React from "react";
import { Helmet } from 'react-helmet';
import graphicDesignContent from '../api/graphicDesignContent.json';

const GraphicDesigning = () => {
  return (
    <>
    <Helmet>
        <title>Graphic Designing Services - AMS</title>
        <meta name="description" content="Transform your ideas into captivating visuals with AMS's graphic designing services. From logos and branding to digital media design, our creative team brings innovation and expertise to every project." />
        <meta name="keywords" content="AMS, graphic designing, logo design, branding, digital media design, illustrations, advertising design" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    <div className="container">
    <div className="row">
      <div className="col-lg-12 col-sm-12 about p-md-5 p-sm-5 p-xs-3">
      <h1 className="text-center">{graphicDesignContent.title}</h1>
      <h5 className="text-center">{graphicDesignContent.subtitle}</h5>
      <p>{graphicDesignContent.description}</p>

      <h5>{graphicDesignContent.comprehensiveDesignServices.heading}</h5>

      <ul>
        {graphicDesignContent.whyChooseAMS.details.map((detail, index) => (
          <li key={index}>{detail}</li>
        ))}
      </ul>

      <h5>{graphicDesignContent.elevateVisualIdentity.heading}</h5>

      <ul>
        {graphicDesignContent.elevateVisualIdentity.details.map((detail, index) => (
          <li key={index}>{detail}</li>
        ))}
      </ul>
    </div>
    </div>
    </div>
    </>
  );
};

export default GraphicDesigning;
