import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import arvr from "../api/arvr.json";
import ServicesCard from "../components/servicesCard";

const ARVR = () => {
  const [textContent, setTextContent] = useState(null);// Initialize with an empty array

  useEffect(() => {
    // No need to fetch JSON data when you've already imported it
    setTextContent(arvr);
  }, []);
  return (
    <>
    <Helmet>
  <title>AR & VR Solutions - AMS</title>
  <meta name="description" content="Immerse yourself in the world of Augmented Reality (AR) and Virtual Reality (VR) with AMS. Explore our innovative AR & VR solutions that revolutionize education, training, and various industries. Join us on a journey of limitless possibilities and cutting-edge technology." />
  <meta name="keywords" content="AMS, AR & VR solutions, augmented reality, virtual reality, immersive experiences, education technology, training solutions" />
  <meta name="robots" content="index, follow" />
</Helmet>

    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-sm-12 about p-md-5 p-sm-5 p-xs-3">
        {textContent ? (<div>
            <h1 className='text-center'>{textContent.title}</h1>
            <h5 className='text-center mb-3'>{textContent.subtitle}</h5>
            {textContent.sections.map((section, index) => (
        // <div key={index}>
        //   <h3>{section.heading}</h3>
        //   <p>{section.content}</p>
        // </div>
        <ServicesCard
          mainTitle={section.heading.split(":")[0].trim()}
          cardTitle={section.heading.split(":")[1].trim()}
          cardText={section.content}
          buttonLabel="View More"         
          />
      ))}
            
          </div>) :(<p>Loading JSON data...</p>)}
      </div>
      </div>
      </div>
      </>
  );
};

export default ARVR;
