import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import CustomCard from '../components/CustomCard';
import hireus from "../api/hireus.json";
import Profiles from '../components/Profiles';

const SearchBar = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
   // alert('working...')
  };

  const filteredData = data.filter((item) => {
    return (
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.designation.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.experience.toLowerCase().includes(searchTerm.toLowerCase()) 
    ||
      item.programmingSkills.some((skill) =>
     skill.toLowerCase().includes(searchTerm.toLowerCase())
    ) 
    ||
      item.softwareSkills.some((skill) =>
        skill.toLowerCase().includes(searchTerm.toLowerCase())
      )

    );
  });


  const [textContent, setTextContent] = useState(null);// Initialize with an empty array

  useEffect(() => {
    // No need to fetch JSON data when you've already imported it
    setTextContent(hireus);
  }, []);

  return (
    <>
    <div className="container">
      <div className='row mx-1'>
      <div className="col-lg-12 col-sm-12 searchbar p-md-4 p-xs-3">
      <div className="input-group">
  <input
    type="text"
    className="form-control p-md-3 p-sm-2"
    placeholder="Search for Name or Designation or Technology or Experience"
    value={searchTerm}
    onChange={handleSearch}
  />
  <span className="input-group-text">
    <i className="bi bi-search"></i>
  </span>
</div>

      </div>
      </div>
      </div>
      {/* <button onClick={handleSearch}>Search</button> */}
      {searchTerm.trim() !== '' && filteredData.length > 0 ? ( 
        filteredData.map((item,index) => (  
          <div className='col-lg-3 col-md-4 col-sm-12 col-xs-12 pb-md-3'>
            <CustomCard
            key={index}
            imageSrc={item.imgUrl}
            cardTitle={item.title}
            cardText={"Designation: " + item.designation}
            cardExp={"Experience: " + item.experience}
            buttonLabel="See Profile"
            to={`/profile/${item.title.replace(/ /g, '-')}`}
          />
          </div>
  
        ))
        ) : (
          <>
          <div className="row mx-1">
        <div className="col-lg-12 col-sm-12 about p-md-5 p-xs-3">
        {textContent ? (<div>
          <div className='text-center'>
            <h1>{textContent.title}</h1>
            <h5>{textContent.subtitle}</h5>
            </div>
            <p className='text-justify'>{textContent.content}</p>
           
          </div>) :(<p>Loading JSON data...</p>)}
      </div>
      </div>
      <div className='row mx-1'>
      <Profiles/>
      </div>
          </>
        )}
    </>
  );
};

export default SearchBar;
