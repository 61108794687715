import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import CustomCard from '../components/CustomCard';
import studentInfo from '../api/students.json';

const Profiles = () => {
  const [shuffledData, setShuffledData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 20;

  useEffect(() => {

    // Function to shuffle the array
    const shuffleArray = (array) => {
      const shuffled = array.slice();
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    };

    // Shuffle the data when the component mounts
    const shuffled = shuffleArray(studentInfo);
    setShuffledData(shuffled);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const visibleData = shuffledData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );




  return (
    <>
      {visibleData.map((item, index) => (
        <div className='col-lg-3 col-md-4 col-sm-12 col-xs-12 pb-md-3'>
        <CustomCard
          key={index}
          imageSrc={item.imgUrl}
          cardTitle={item.title}
          cardText={"Designation: " + item.designation}
          cardExp={"Experience: " + item.experience}
          buttonLabel="See Profile"
          to={`/profile/${item.title.replace(/ /g, '-')}`}
        />
        </div>
      ))}
<ReactPaginate
      pageCount={Math.ceil(shuffledData.length / itemsPerPage)}
      pageRangeDisplayed={5}
      marginPagesDisplayed={2}
      onPageChange={({ selected }) => {
        setCurrentPage(selected);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      containerClassName={'pagination'}
      activeClassName={'active'}
    />



    </>
  );
};

export default Profiles;
