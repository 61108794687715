// LogoSlider.js

import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const LogoSlider = () => {
  const logos = [
    "http://auramediasolutions.com/2023/images/it_logos/accenture.jpg",
    "http://auramediasolutions.com/2023/images/it_logos/cognigent.jpg",
    "http://auramediasolutions.com/2023/images/it_logos/deloitte.jpg",
    "http://auramediasolutions.com/2023/images/it_logos/google.jpg",
    "http://auramediasolutions.com/2023/images/it_logos/ibm.jpg",
    "http://auramediasolutions.com/2023/images/it_logos/infosys.jpg",
    "http://auramediasolutions.com/2023/images/it_logos/microsoft.jpg",
    "http://auramediasolutions.com/2023/images/it_logos/tcs.jpg",
    "http://auramediasolutions.com/2023/images/it_logos/wipro.jpg"
  ];

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div className='contianer-fluid'>
        <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 p-md-5 p-sm-5'>
            <h1 className="text-center m-5">Our Partners</h1>
    <div className="logo-slider">
      <Slider {...settings}>
        {logos.map((item, index) => (
          <div key={index} className='logo-div'>
            <img src={item} alt={`logo-${index}`} className="logo" />
          </div>
        ))}
      </Slider>
    </div>
    </div>
    </div>
    </div>
  );
};

export default LogoSlider;
