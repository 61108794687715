import ams_office from "../assets/images/ams-office-building.jpg";
const Footer = () => {
    return (
<footer>
      <div className="container-fluid unique-gutter">
        <div className="container p-lg-5 p-md-5 p-sm-5">
        <div className="row">

          <div className="col-md-3 col-sm-12 p-md-1 p-sm-5 p-xs-3">
          <h4 className="text-white-footer">AMS Office</h4>
          <a href="#">
            <img src={ams_office} width='100%' alt='AMS Office Building' title="AMS Office Building" className="pe-3"/>
            </a>
            </div>      
          <div className="col-md-3 col-sm-12 p-md-1 p-sm-5 p-xs-3">
            <h4 className="text-white-footer">Navigate Site</h4>
            <ul class="p-0 list-unstyled">
               <li> <a href="/" className="text-decoration-none text-white-footer">Home</a></li>
               <li><a href="/about-us" className="text-decoration-none text-white-footer">About Us</a></li>
               <li><a href="/why-ams" className="text-decoration-none text-white-footer">Why AMS</a></li>
               <li> <a href="/internship" className="text-decoration-none text-white-footer">Internship</a></li>
               <li><a href="/gallery" className="text-decoration-none text-white-footer">Gallery</a></li>
               <li><a href="/contact-us" className="text-decoration-none text-white-footer">Contact Us</a></li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-12 p-md-1 p-sm-5 p-xs-3">
            <h4 className="text-white-footer">Policies</h4>
            <ul class="p-0 list-unstyled">
                <li><a href="#" className="text-decoration-none text-white-footer">Privacy Policy</a></li>
                <li><a href="#" className="text-decoration-none text-white-footer">Terms & Conditions</a></li>
                <li><a href="#" className="text-decoration-none text-white-footer">Return Policy
                </a></li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-12 p-md-1 p-sm-5 p-xs-3">
            <h4 className="text-white-footer">Social Media</h4>
            <ul class="p-0 list-unstyled">
<li><a href="#" className="text-decoration-none text-white-footer"><span className="bi-meta meta pe-3"></span>Meta</a></li>
<li><a href="#" className="text-decoration-none text-white-footer"><span className="bi-twitter twitter pe-3"></span>Twitter</a></li>
<li><a href="#" className="text-decoration-none text-white-footer"><span className="bi-instagram insta pe-3"></span>Instagram</a></li>
<li><a href="#" className="text-decoration-none text-white-footer"><span className="bi-youtube youtube pe-3"></span>Youtube</a></li></ul>
          </div>
          </div>
          </div>
          <div className="containerf-fluid footer-address">
          <div className="row">
            <div className="col-12 text-center">
            <h4><span>©</span> 2023 AURA MEDIA SOLUTIONS(AMS)</h4>
                        <p>Rajputh Star'D Enclave, Sompalli</p>
                        <p> <span class="bi bi-telephone-fill"></span> 9494815454</p>
            </div>
          </div>
      </div>
      </div>
      </footer>
    );
  };
  
  export default Footer;
  