import React from "react";
import { Helmet } from 'react-helmet';
import advertisingServicesContent from "../api/advertisingServicesContent.json";

const Advertising = () => {
  return (
    <>
    <Helmet>
  <title>Advertising Services - AMS</title>
  <meta name="description" content="Amplify your brand with AMS's advertising services. From eye-catching hoardings and theater ads to dynamic social media campaigns, we create impactful strategies to elevate your brand presence." />
  <meta name="keywords" content="AMS, advertising services, hoardings, theater advertising, social media campaigns, marketing, brand visibility" />
  <meta name="robots" content="index, follow" />
</Helmet>
    <div className="container">
    <div className="row">
      <div className="col-lg-12 col-sm-12 about p-md-5 p-sm-5 p-xs-3">
      <h1 className="text-center">{advertisingServicesContent.title}</h1>
      <h5 className="text-center">{advertisingServicesContent.subtitle}</h5>
      <p className="text-justify">{advertisingServicesContent.description}</p>

      {/* Comprehensive Advertising Services */}
      <h5>{advertisingServicesContent.comprehensiveAdvertisingServices.heading}</h5>
      <p className="text-justify">{advertisingServicesContent.comprehensiveAdvertisingServices.description}</p>

      {/* Why Choose AMS for Advertising Services */}
      <h5>{advertisingServicesContent.whyChooseAMS.heading}</h5>
      <ul>
        {advertisingServicesContent.whyChooseAMS.details.map((detail, index) => (
          <li key={index} className="text-justify">{detail}</li>
        ))}
      </ul>

      {/* Fueling Your Brand's Growth */}
      <h5>{advertisingServicesContent.fuelingBrandGrowth.heading}</h5>
      <p className="text-justify">{advertisingServicesContent.fuelingBrandGrowth.description}</p>

      {/* Closing Statement */}
      <p className="text-justify">{advertisingServicesContent.closingStatement}</p>
    </div>
    </div>
    </div>
    </>
  );
};

export default Advertising;
