import React from "react";
import { Helmet } from 'react-helmet';
import content from "../api/softwareTraining.json";
import CourseCard from "./CourseCard";
import courses from "../api/courses.json"

const SoftwareTraining = () => {
  return (
    <>
    <Helmet>
        <title>Software Training Programs - AMS</title>
        <meta name="description" content="Enhance your skills with AMS's software training programs. Our comprehensive courses cover a range of technologies, including software development, AR & VR, and more. Join us for hands-on learning led by experienced professionals." />
        <meta name="keywords" content="AMS, software training, technology courses, programming, AR & VR training, hands-on learning" />
        <meta name="robots" content="index, follow" />
      </Helmet>

    <div className="container">
    <div className="row">
      <div className="col-lg-12 col-sm-12 about p-md-5 p-sm-5 p-xs-3">
      <h1 className="text-center">{content.title}</h1>
          <h5 className="text-center">{content.subtitle}</h5>
          <p>{content.description}</p>
          <h5>{content.unleashYourPotential.heading}</h5>
          <p>{content.unleashYourPotential.details}</p>
          <h5>{content.whyChooseAMS.heading}</h5>
          <ul>
            {content.whyChooseAMS.details.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
          <h5>{content.comprehensiveTrainingOfferings.heading}</h5>
          <p>{content.comprehensiveTrainingOfferings.journeyStartsHere.details}</p>
          <p>{content.comprehensiveTrainingOfferings.joinUs.details}</p>
          <div className="row">
            {courses.map((course,index)=>(    
              <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-md-3'>           
                <CourseCard key={index} courseTitle={course.title} qualificationTitle='Qualification' 
                qualification={course.qualification}
                durationTitle='Duration' 
                courseDuration={course.duration} 
                technologiesTitle='Technologies' 
                technologies={course.technologies.map((item,index)=>
                    (
                    //console.log(item)
                    <tr key={index}>
                    <td className="qualify">{index+1}</td>
                  <td className="qualify">{item}</td>
                  </tr>
                    )
                )}/>
</div>

                   ) )}
                
            </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default SoftwareTraining;
