import { Carousel } from "react-bootstrap";
import { TypeAnimation } from 'react-type-animation';
import banner1 from "../assets/images/ams-software-development-banner.jpg"

const Banner = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={banner1}
          alt="First slide"
        />
        <Carousel.Caption>
        
          
            <h4>
                <TypeAnimation
      sequence={[
        // Same substring at the start will only be typed out once, initially
        'Software Development',
        1000,
        'Software Training',
        1000,
        'Graphic Designing',
        1000,
        'Printing Services',
        1000,
        'Internship',
        1000,
      ]}
      wrapper="span"
      speed={50}
      style={{ fontSize: '2em', display: 'inline-block' }}
      repeat={Infinity}
    />
    
    
    </h4>
            <p>Where You Experience The Technology & Innovation</p>
     
        </Carousel.Caption>
      </Carousel.Item>
      
      
    </Carousel>
  );
};

export default Banner;
