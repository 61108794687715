import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
function CustomCard(props) {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    // <div className='col-lg-3 col-md-4 col-sm-12 col-xs-12 pb-md-3'>
    <Card className={props.class}>
      <Card.Img variant="top" src={props.imageSrc} />
      <Card.Body>
        <Card.Title className='text-center'>{props.cardTitle}</Card.Title>
        <Card.Text className='text-justify'>{props.cardText}</Card.Text>
        <p>{props.cardExp}</p>
        <Link to={props.to}>
          <div className='text-center'>
        <Button variant="primary" onClick={scrollToTop}>{props.buttonLabel}</Button>
        </div>
        </Link>
      </Card.Body>
    </Card>
    // </div>
  );
}

export default CustomCard;
