import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import internship from "../api/internship.json";
import shortTerm from "../api/shortterm_internship.json";
import longTerm from "../api/longterm_internship.json";

const Internship = () => {
  const [textContent, setTextContent] = useState(null);// Initialize with an empty array

  useEffect(() => {
    // No need to fetch JSON data when you've already imported it
    setTextContent(internship);
  }, []);

  return (
    <>
    <Helmet>
  <title>Internship Opportunities - AMS</title>
  <meta name="description" content="Join AMS for a rewarding internship experience in a rural setting. Gain hands-on experience in software development, AR & VR technologies, and more. Explore our AC classrooms, interact with experienced professionals, and embark on a journey of learning and growth." />
  <meta name="keywords" content="AMS, internship opportunities, software development internship, AR & VR internship, hands-on learning, professional interaction, career growth" />
  <meta name="robots" content="index, follow" />
</Helmet>

    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-sm-12 about p-md-5 p-sm-5">
        {textContent ? (<div>
            <h1>{textContent.title}</h1>
            <h5>{textContent.subtitle}</h5>
            {textContent.content.map((paragraph, index) => (
              <p key={index} className='text-justify'>{paragraph}</p>
            ))}
            <h3>Features:</h3>
            <ul>
              {Object.entries(textContent.features).map(([feature, description]) => (
                <li key={feature} className='text-justify'>
                  <strong>{feature}:</strong> {description}
                </li>
              ))}
            </ul>
          </div>) :(<p>Loading JSON data...</p>)}
    </div>
    </div>
    <div className='row'>
      <h2 className='text-center'>Internship Courses We Provide</h2>
      <div className='col-lg-6 col-md-6 col-sm-12 p-md-3 p-sm-5'>
      <h3 className='text-center my-3'>Short Term Interhsip</h3>
      <div className='table-responsive'>
                <table className='table table-bordered'>
                  <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Course Name</th>
                    <th>Duration</th>
                  </tr>
                  </thead>
                  <tbody>

                  {
                    shortTerm.map((item,index)=>(
                      
                      <tr key={index}>
                        
                      <td>{index+1}</td>
                      <td>{item.title}<br/>(
                      {
                        item.technologies.map((tech,index)=>(
                          <span>{tech},</span>
                        ))
                      }
                      )
                      </td>
                      <td>{item.duration} </td>
                      
                      </tr>
                     
                    ))
                  }
                  </tbody>
                </table>
                </div>
      </div>
      <div className='col-lg-6 col-md-6 col-sm-12 p-md-3 p-sm-5'>
      <h3 className='text-center my-3'>Long Term Interhsip</h3>
      <div className='table-responsive'>
      <table className='table table-bordered'>
                  <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Course Name</th>
                    <th>Duration</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    longTerm.map((item,index)=>(
                      
                      <tr key={index}>
                        
                      <td>{index+1}</td>
                      <td>{item.title}<br/>(
                      {
                        item.technologies.map((tech,index)=>(
                          <span>{tech},</span>
                        ))
                      }
                      )
                      </td>
                      <td>{item.duration} </td>
                      
                      </tr>
                     
                    ))
                  }
                  </tbody>
                </table>
                </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default Internship;
