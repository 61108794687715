import { useEffect, useState } from 'react';
import { fetchData } from '../api/Api';

export function useContent() {
  const [textContent, setTextContent] = useState([]);

  useEffect(() => {
    async function fetchTextContent() {
      // Check if cached content exists in local storage
      const cachedContentJSON = localStorage.getItem('cachedContent');

      if (cachedContentJSON) {
        // Use cached content by parsing it from JSON
        const cachedContent = JSON.parse(cachedContentJSON);
        setTextContent(cachedContent);
      } else {
        // Fetch content from the server
        try {
          const data = await fetchData('/content.php');
          if (data && data.length > 0) {
            // Store content in local storage as JSON
            localStorage.setItem('cachedContent', JSON.stringify(data));
            setTextContent(data);
          } else {
            setTextContent('No data available');
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    fetchTextContent();
  }, []);

  return textContent;
}
