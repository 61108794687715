import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const testimonials = [
  {
    id: 1,
    name: 'Narayan Yoga, Dubai',
    text: 'Of course, India is the core hub for IT, Where AMS involves 100% latest trends & technologies to bring into our lives',
    designation: 'Founder & CEO of NYogaDubai'
  },
  {
    id: 2,
    name: 'Ch.Prithvi Raj',
    text: 'I have found AMS in our Rural area, is the best established organization where I can get all the IT & Printing services with professionalism',
    designation: 'Rangaraaya Medical College, Kakinada'
  }
];

const SliderTestimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="container">
       <div className="row">
        <div className="col testimonials p-md-5 p-sm-5">
        <h1 className="mb-5 text-center">What our clients saying about us</h1>
    <div className="slider-testimonials text-center">
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial text-center">
            <p className="testimonial-text">{testimonial.text}</p>
            <p className="testimonial-name">- {testimonial.name}</p>
            <p className="testimonial-designation">- {testimonial.designation}</p>
          </div>
        ))}
      </Slider>
    </div>
    </div>
    </div>
    </div>
  );
};

export default SliderTestimonials;
