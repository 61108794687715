import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hireus from "../api/hireus.json";
import Profiles from '../components/Profiles';
import SearchBar from '../components/SearchBar';
import studentInfo from '../api/students.json';


const HireUs = () => {
  const [textContent, setTextContent] = useState(null);// Initialize with an empty array

  useEffect(() => {
    // No need to fetch JSON data when you've already imported it
    setTextContent(hireus);
  }, []);
  return (
    <>
    <Helmet>
  <title>Hire AMS - Your Partner in Technology</title>
  <meta name="description" content="AMS offers software training and internships, producing skilled individuals ready for employment. Explore our platform where IT companies can choose employees with hands-on experience trained at AMS. Partner with us and benefit from a pool of talented professionals." />
  <meta name="keywords" content="AMS, hire us, software training, internships, skilled professionals, technology employment, IT companies" />
  <meta name="robots" content="index, follow" />
</Helmet>

    <div className="container">
      <div className='row'>
      <SearchBar data={studentInfo} />
      </div>
      {/* <div className="row mx-1">
        <div className="col-lg-12 col-sm-12 about p-md-5 p-xs-3">
        {textContent ? (<div>
          <div className='text-center'>
            <h1>{textContent.title}</h1>
            <h5>{textContent.subtitle}</h5>
            </div>
            <p className='text-justify'>{textContent.content}</p>
           
          </div>) :(<p>Loading JSON data...</p>)}
      </div>
      </div>
      <div className='row mx-1'>
      <Profiles/>
      </div> */}
      </div>
      </>
  ) 
};

export default HireUs;
