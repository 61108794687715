import { useParams } from 'react-router-dom';
import studentInfo from '../api/students.json';
import profiles from '../api/final_profiles_output.json'

const StudentProfile = () => {
  const { title  } = useParams();
  const profile = profiles.find((item) => (item.CurriculumVitae_Name).replace(/ /g, '-') === title);

  if (!profile) {
    return <div>Profile not found.</div>;
  }

  return(

<div className="container">
                <div className="row">
                    <div className="col-10 m-auto">
                        <h1 className="text-center my-lg-5 my-md-4 my-sm-3">Curriculum Vitae</h1>
                    </div>
                </div>
                <div className="row ams-student-name-photo">
                    <div className='col-10 m-auto d-flex'>
                    <div className="col-8 ams-name-email">
                        <h5>{profile.CurriculumVitae_Name}</h5>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Gender: </td>
                                    <td>{profile.CurriculumVitae_Gender}</td>
                                </tr>
                                <tr>
                                    <td>Nationality: </td>
                                    <td>{profile.CurriculumVitae_Nationality}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="m-0"><i className="bi bi-telephone-fill"></i> {profile.CurriculumVitae_Phone}</p>
                        <p className="m-0"> <i className="bi-envelope-fill"></i> {profile.CurriculumVitae_Email}</p>
                    </div>
                    <div className="col-4  photo text-end">
                        <img className="rounded" src={profile.CurriculumVitae_ProfilePicture} width='150px' />
                    </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-10 m-auto'>
                        <h5>Career Objective</h5>
                        <p>{profile.CurriculumVitae_CareerObjective}</p>
                    </div>
                    <div className='col-10 m-auto'>
                        <h5>Skills:</h5>
                        <h5>Programming Skills:</h5>
                        <ul>
                        {profile.CurriculumVitae_Skills_Programming.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
                        </ul>
                       <h5>software Skills:</h5>
                       <ul>
                       {profile.CurriculumVitae_Skills_Software.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
                       </ul>
                       <h5>Language Skills:</h5>
                       <ul>
                       {profile.CurriculumVitae_Skills_Language.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
                       </ul>
                    </div>
                    <div className='col-10 m-auto'>
                    <h5>Experience</h5>
                    <ul>
                        <li>I have one year experience in Front-End Development in AMS.</li>
                    </ul>
                    </div>
                    <div className='col-10 m-auto'>
                    <h5>Projects:</h5>
                    <table className='table table-striped border'>
                        <thead>
                            <tr>
                                <th>Project Name</th>
                                <th>Project Link</th>
                                <th>Project Description</th>
                            </tr>                            
                        </thead>
                        <tbody>
                        {profile.CurriculumVitae_Projects.map((project, index) => (
                <tr key={index}>
                  <td>{project.ProjectName}</td>
                  <td>{project.ProjectLink}</td>
                  <td>{project.ProjectDescriptionLink}</td>
                </tr>
              ))}
                        </tbody>
                    </table>
                    </div>
                    <div class="row">
            <div className="col-10 m-auto">
                <h4>Education :</h4>
                <ol>
                {profile.CurriculumVitae_Education.map((education, index) => (
                <li key={index}>{education}</li>
              ))}
                </ol>
            </div>
        </div>
        <div className="row">
            <div className="col-10 m-auto">
                <h4>General Information:</h4>
                <table className="general-info table border">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>:</td>
                        <td>{profile.CurriculumVitae_GeneralInformation_Name}</td>
                    </tr>
                    <tr>
                        <td>Father Name</td>
                        <td>:</td>
                        <td>{profile.CurriculumVitae_GeneralInformation_FatherName}</td>
                    </tr>
                    <tr>
                        <td>DOB</td>
                        <td>:</td>
                        <td>{profile.CurriculumVitae_GeneralInformation_DOB}</td>
                    </tr>
                    <tr>
                        <td>Marital Status</td>
                        <td>:</td>
                        <td>{profile.CurriculumVitae_GeneralInformation_MaritalStatus}</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>:</td>
                        <td>{profile.CurriculumVitae_GeneralInformation_Address_Street}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>{profile.CurriculumVitae_GeneralInformation_Address_City}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>{profile.CurriculumVitae_GeneralInformation_Address_District}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>{profile.CurriculumVitae_GeneralInformation_Address_State}</td>
                    </tr>
                </tbody></table>

            </div>
        </div>
        <div className="row">
            <div className='col-10 m-auto d-flex'>
            <div className="col-5">
                <h4>Place:</h4>
                <h4>Date:</h4>
            </div>
            <div className="col-5 d-flex align-items-end justify-content-end">
                <h4>Signature:<small>({profile.Signature})</small></h4>
            </div>
            </div>
        </div>
                </div>
            </div>
  )
}
  
  export default StudentProfile;
  
