import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter,Routes,Route,useParams} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import MultipleDatePicker from './MultipleDatePicker';
import Navigation from './Navigation';
import Footer from './components/Footer';
import CustomCard from "./components/CustomCard"
import studentInfo from './api/students.json';
import StudentProfile from './components/studentProfile';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
    <Navigation/> 
     <Footer/>
 
    </div>
    </BrowserRouter>
  );
}

export default App;
