import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import activities from "../api/activities.json";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import photoData from "../api/socail_larger_data.json"
import thumnailData from "../api/socail_thumbnail_data.json"

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const SocialActivities = () => {
  const [textContent, setTextContent] = useState(null);// Initialize with an empty array
  const [data, setData] = useState([]);
  const [thumb, setThumb] = useState([]);
  const [index, setIndex] = useState(-1);


  useEffect(() => {
    // No need to fetch JSON data when you've already imported it
    setTextContent(activities);
    setData(photoData)
    setThumb(thumnailData)
  }, []);
  return (
    <>
    <Helmet>
  <title>Social Activities - Making a Difference with AMS</title>
  <meta name="description" content="Explore the social impact created by AMS through our various initiatives. From charity work and aiding the less fortunate to conducting flood relief activities, AMS is dedicated to making a positive difference in the community. Join us in contributing to a better tomorrow." />
  <meta name="keywords" content="AMS, social activities, charity, community impact, flood relief, social responsibility" />
  <meta name="robots" content="index, follow" />
</Helmet>

    <div className="container">
    <div className="row">
      <div className="col-lg-12 col-sm-12 about p-md-5 p-sm-5 p-xs-3">
      <h1 className="text-center">{activities.title}</h1>
          <h5 className="text-center">{activities.subtitle}</h5>
          <p>{activities.description}</p>
          <h5>{activities.outreachInitiatives.heading}</h5>
          <ul>
            {activities.outreachInitiatives.items.map((item, index) => (
              <li key={index} className='text-justify'>
                <strong>{item.title}</strong> {item.description}
              </li>
            ))}
          </ul>
          <h5>{activities.helpingTheNeedy.heading}</h5>
          <p className='text-justify'>{activities.helpingTheNeedy.description}</p>
          <h5>{activities.visionForBetterFuture.heading}</h5>
          <p className='text-justify'>{activities.visionForBetterFuture.description}</p>
          <h5>{activities.joinUsInMakingADifference.heading}</h5>
          <p className='text-justify'>{activities.joinUsInMakingADifference.description}</p>
          <p className='text-justify'>{activities.progressAndResponsibility.description}</p>
    </div>
    </div>
    <div className="row">
        <div className="col-lg-12 col-sm-12 gallery p-sm-5 p-xs-3">
    <PhotoAlbum photos={thumb.map((item) => (
        {
          src: item.imageurl,
          width: item.width,
          height: item.height,
        }
      ))} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />

<Lightbox
    slides={
      data.map((item) => (
        {
          src: item.imageurl,
          width: item.width,
          height: item.height,
        }
      ))
    }
    open={index >= 0}
    index={index}
    close={() => setIndex(-1)}
    // enable optional lightbox plugins
    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
/>
</div>
</div>
    </div>
    </>
  );
};

export default SocialActivities;
