import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import contact from "../api/contact.json";

const ContactUs = () => {
  const [textContent, setTextContent] = useState(null);// Initialize with an empty array

  useEffect(() => {
    // No need to fetch JSON data when you've already imported it
    setTextContent(contact);
  }, []);
  return (
    <>
    <Helmet>
  <title>Contact AMS - Let's Connect</title>
  <meta name="description" content="Get in touch with AMS for all your technology, design, and advertising needs. Our team is ready to answer your questions, discuss collaboration opportunities, and assist you in bringing your projects to life. Let's connect and innovate together!" />
  <meta name="keywords" content="AMS, contact us, technology, design, advertising, collaboration" />
  <meta name="robots" content="index, follow" />
</Helmet>

    <div className="container">
    <div className="row">
      <div className="col-lg-12 col-sm-12 about p-md-5 p-sm-5 p-xs-3">
      <h1 className="text-center">{contact.title}</h1>
          <h5 className="text-center">{contact.subtitle}</h5>
          <p>{contact.description}</p>

          <h3>{contact.getInTouch.heading}</h3>
          <ul>
            {contact.getInTouch.contactMethods.map((method, index) => (
              <li key={index}>
                <strong>{method.label}</strong> {method.details}
              </li>
            ))}
          </ul>
          <p>{contact.getInTouch.communicationPreferences}</p>

          <h3>{contact.workingHours.heading}</h3>
          <p>{contact.workingHours.details}</p>
          <ul>
            {contact.workingHours.hoursOfOperation.map((hours, index) => (
              <li key={index}>
                <strong>{hours.day}:</strong> {hours.hours}
              </li>
            ))}
          </ul>

          <h3>{contact.collaborationInfo.heading}</h3>
          <p>{contact.collaborationInfo.details}</p>
    </div>
    </div>
    </div>
    </>
  );
};

export default ContactUs;
