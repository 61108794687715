import {Button} from "react-bootstrap"
import { Link } from 'react-router-dom';

const WhyAMS = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
    return (
      <div className="container-fluid why-ams-bg">
      <div className="container">
        <div className="row">
        <div className="col-lg-12 col-sm-12 why-ams  p-md-5 p-sm-5 p-xs-3">
               <h2 className='text-center'>Why AMS?</h2>
              <h6 className='text-center pb-2'>Discover the AMS Difference</h6>
              <p className='text-justify'>At AMS, we are not just a software company. We are a testament to innovation, a one stop hub for a multitude of services, a team of experienced professionals, and a driving force for change. Here is why choosing AMS is a choice that sets you on a path towards excellence...</p>
              <div className='text-center'>
              <Link to='why-ams' onClick={scrollToTop}>
            <Button variant="primary" className="my-4 mx-auto">
              Read More
            </Button>
          </Link>
          </div>
         
</div>

          </div>
      </div>
      </div>
    );
  };
  
  export default WhyAMS;
  