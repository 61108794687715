import {useState,useEffect} from 'react'
import { Helmet } from 'react-helmet';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import photoData from "../api/photoURLs.json"
import thumnailData from "../api/thumbnail_data.json"

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import photos from '../assets/Photos';



const Gallery = () => {

  const [data, setData] = useState([]);
  const [thumb, setThumb] = useState([]);

  useEffect(() => {
    setData(photoData)
    setThumb(thumnailData)

  }, []);






  const [index, setIndex] = useState(-1);

  return (
    <>
<Helmet>
  <title>Gallery - AMS in Pictures</title>
  <meta name="description" content="Explore the visual journey of AMS through our gallery. View snapshots of our innovative projects, training sessions, social activities, and more. See the impact of technology and creativity brought to life by AMS." />
  <meta name="keywords" content="AMS, gallery, technology projects, training sessions, social activities, creativity" />
  <meta name="robots" content="index, follow" />
</Helmet>

    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 col-sm-12 gallery p-sm-5 p-xs-3">
    <PhotoAlbum photos={thumb.map((item) => (
        {
          src: item.imageurl,
          width: item.width,
          height: item.height,
        }
      ))} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />

<Lightbox
    slides={
      data.map((item) => (
        {
          src: item.imageurl,
          width: item.width,
          height: item.height,
        }
      ))
    }
    open={index >= 0}
    index={index}
    close={() => setIndex(-1)}
    // enable optional lightbox plugins
    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
/>
</div>
</div>
</div>
</>
  );
};

export default Gallery;
