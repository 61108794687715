
const Location = () => {
  return (
    <div className="container-fluid unique-gutter">
      <div className="row">
      <div className="col-lg-12 col-sm-12 location"></div>
        <h1 className="mb-5 text-center">Our Business Area</h1>
        
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.758067787944!2d81.85473547480834!3d16.48778358425402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a37e7c77b66f679%3A0x668a6579d880705f!2sAura%20Media%20Solutions!5e0!3m2!1sen!2sin!4v1687833651998!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
  );
};

export default Location;
