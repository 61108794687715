import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import data from "../api/data.json"
import founderPhoto from '../assets/images/ams-jeevan-sir-pic.jpg'

const AboutUs = () => {
  const [textContent, setTextContent] = useState({ pages: [] }); // Initialize with an empty array

  useEffect(() => {
    setTextContent(data);
  }, []);
  return (
    <>
    <Helmet>
        <title>About AMS - Pioneering Technology in Rural Innovation</title>
        <meta name="description" content="AMS, established in 2019, is a rural-based software company committed to spreading technology and innovation. Learn about our journey, services, and our mission to create employment and foster technological advancement in every area." />
        <meta name="keywords" content="AMS, about us, rural technology, software company, innovation, employment, technology spreading" />
        <meta name="robots" content="index, follow" />
      </Helmet>

    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-sm-12 about p-md-5 p-sm-5 p-xs-3">
          {textContent?.pages ? (
            <div>
              
              {textContent.pages.map((page, index) => (
                <div key={index} className='col-md-12'>
                  <h2>{page.title}</h2>
                  <div className={'row about_flex'+index+1}>
                    <div className={`col-md-7 col-sm-12 col-xs-12 about-col-${index+1}`}>
                  <p className='text-justify'>{page.content}</p>
                    </div>
                    <div className='col-md-4 col-sm-12 col-xs-12'>
                  <figure>
                  <img src={founderPhoto} width="100%" alt="AMS founder & CEO, Jeevan Varma Sir Photo" className={'rounded about_'+(index+1)} title="AMS founder & CEO, Mr. Jeevan Varma"/>
                  <figcaption className='text-center'>AMS Founder & CEO</figcaption>
                  </figure>
                  </div>
                  </div>
                  {/* <dl>
                    {page.dt_dd_pairs.map((pair, pairIndex) => (
                      <div key={pairIndex}>
                        <dt>{pair.term}</dt>
                        <dd className='text-justify'>{pair.description}</dd>
                      </div>
                    ))}
                  </dl> */}
                  <table className='table table-bordered'>
                    <tbody>
                      
                      {page.dt_dd_pairs.map((pair, pairIndex) => (
                      <tr key={pairIndex}>
                        <td className='align-middle p-3'><strong>{pair.term}</strong></td>
                        <td className='text-justify p-3'>{pair.description}</td>
                      </tr>
                    ))}
                     
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          ) : (
            <p>Loading JSON data...</p>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default AboutUs;
