import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import whyams from "../api/whyams.json";

const WhyAMS = () => {
  const [textContent, setTextContent] = useState(null);// Initialize with an empty array

  useEffect(() => {
    // No need to fetch JSON data when you've already imported it
    setTextContent(whyams);
  }, []);
  return (
    <>
    <Helmet>
  <title>Why Choose AMS - Innovate with Confidence</title>
  <meta name="description" content="Discover the compelling reasons to choose AMS for your technology needs. From our commitment to innovation and all-in-one services to an experienced and professional team, cost-effectiveness, and a unique village environment, AMS is your partner in success." />
  <meta name="keywords" content="AMS, why choose us, innovation, technology services, professional staff, cost-effective, village environment" />
  <meta name="robots" content="index, follow" />
</Helmet>

    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-sm-12 about p-md-5 p-sm-5 p-xs-3">
        {textContent ? (<div>
            <h1 className='text-center'>{textContent.title}</h1>
            <h5 className='text-center mb-3'>{textContent.subtitle}</h5>
            <p>{textContent.content}</p>
            <dl>
                    {textContent.dt_dd_pairs.map((pair, pairIndex) => (
                      <div key={pairIndex}>
                        <dt>{pair.term}</dt>
                        <dd className='text-justify'>{pair.description}</dd>
                      </div>
                    ))}
                  </dl>
            
          </div>) :(<p>Loading JSON data...</p>)}
</div>
    </div>
    </div>
    </>
  );
};

export default WhyAMS;