import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CustomCard from '../components/CustomCard';
import { useContent } from '../api/useContent';
import services from "../api/services.json";

const Services = () => {
const endIndex = 9;
  const content = useContent();

  return (
    <div className="container" id="home-services">
        <h1 className="mb-5 pt-5 text-center">Services</h1>
        <div className='row mx-1'>
    
        { 
        services.map((item, index) => (
          <div className='col-lg-4 col-md-8 col-sm-12 col-xs-12 pb-md-3'>
         <CustomCard
         class={"services_"+index}         
         key={index}
         cardTitle={item.title}
         cardText={item.description}
         buttonLabel="View More"
         to={item.link_text}         
       />
       </div>
      ))}







      {/* <CustomCard
        imageSrc="holder.js/100px180"
        cardTitle="Software Training"
        cardText="We specialize in providing top-notch software training programs that empower individuals to acquire the skills and knowledge needed to excel in the fast-paced world of software development."
        buttonLabel="View More"
        to="/services/software-training"
      />
      <CustomCard
        imageSrc="holder.js/100px180"
        cardTitle="Graphic Designing"
        cardText="we are a team of skilled graphic designers with a shared love for creativity and aesthetics. With 20 years of experience in the graphic design field, we take pride in crafting unique... "
        buttonLabel="View More"
        to="/services/graphic-designing"
      />
  
      <CustomCard
        imageSrc="holder.js/100px180"
        cardTitle="Printing Services"
        cardText="At AMS, we offer a wide range of printing solutions to meet your business and personal needs. With state-of-the-art technology and a team of skilled professionals, we deliver high-quality prints that make a lasting impression."
        buttonLabel="View More"
        to="/services/printing"
      />
      <CustomCard
        imageSrc="holder.js/100px180"
        cardTitle="Internship"
        cardText="Are you a college or university student with a passion for software development and a desire to gain real-world experience? Look no further! AMS is excited to offer internship opportunities..."
        buttonLabel="View More"
        to="/internship"
      />
      <CustomCard
        imageSrc="holder.js/100px180"
        cardTitle="AR & VR"
        cardText="We specialize in developing cutting-edge AR and VR solutions that cater to a wide range of industries, from education(Schools, Colleges and Universities) to entertainment and beyond."
        buttonLabel="View More"
        to="/augmented-virtual-reality"
      /> */}


</div>
    </div>
  );
};

export default Services;
