import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
function ServicesCard(props) {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className='col mb-2'>
    <Card className='ams-card'>
    <Card.Header className='h3 ams-card-header'>{props.mainTitle}</Card.Header>
      <Card.Body>
        <Card.Title className='text-center'>{props.cardTitle}</Card.Title>
        <Card.Text className='text-justify'>{props.cardText}</Card.Text>
        <Link to={props.to}>
          <div className='text-center'>
        <Button variant="primary" onClick={scrollToTop}>{props.buttonLabel}</Button>
        </div>
        </Link>
      </Card.Body>
    </Card>
    </div>
  );
}

export default ServicesCard;
